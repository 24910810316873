<template>
  <el-container class="home_container">
    <!-- Aside -->
    <el-aside :width="isCollapse ? '64px' : '210px'">
      <el-menu @select="handleSelect" :collapse="isCollapse" :default-active="activePath" background-color="#304156" text-color="#fff" active-text-color="#409eff" :collapse-transition="false" router>
        <div @click="toIndex" class="">
          <img src="https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif" alt="">
          <span v-if="!isCollapse">后台管理系统</span>
        </div>
        <el-submenu v-for="(menus, index) in menusList" :key="menus.id" :index="`${index}`">
          <template slot="title"><i :class="menus.icon ? menus.icon : 'el-icon-s-promotion'"></i><span>{{menus.title}}</span></template>
           <el-menu-item v-for="item in menus.children" :key="item.id" :index="item.path">{{item.title}}</el-menu-item>
        </el-submenu>
      </el-menu>
    </el-aside>

    <el-container>
      <!-- Header -->
      <el-header>
        <div class="navbar">
          <div class="navbar_l">
            <div @click="getCollapse" class="header_collapse"><i :class="!isCollapse ? 'el-icon-s-fold' : 'el-icon-s-unfold'"></i></div>
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item><span @click="toIndex" class="bread_index">首页</span></el-breadcrumb-item>
              <el-breadcrumb-item v-for="(item, index) in breadcrumbList" :key="index">{{item}}</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="navbar_r">
            <el-select v-model="value" @change="getSelectValue" :remote-method="remoteMethod" :loading="loading" placeholder="请输入关键词" remote filterable clearable>
              <template slot="prefix"><i class="el-icon-search"></i></template>
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
            <div @click="getFullScreen" class="header_full"><i class="el-icon-full-screen" /></div>
            <el-dropdown @command="getCommand">
              <span class="el-dropdown-link">
                {{username}}<i class="el-icon-caret-bottom el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown" class="header_dropdown">
                <el-dropdown-item command="mine">个人中心</el-dropdown-item>
                <el-dropdown-item command="logout" divided>退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="tag">
          <el-scrollbar>
            <el-tag v-for="(tag, index) in tags" :key="tag.name" @click="getTagIndex(tag, index)" @close="closeTag(tag, index)" :closable="tag.closable" :class="currentActive === index ? 'tag_active' : ''">{{tag.name}}</el-tag>
          </el-scrollbar>
        </div>
      </el-header>
      <!-- Main -->
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import {delCookies} from "../utils/utils";

export default {
  name: 'Home',
  data() {
    return {
      menusList: [
        {
          id: 1,
          title: '博客',
          icon: 'el-icon-menu',
          children: [
            {
              id: 10,
              path: '/article',
              title: '文章',
              parentTitle: '博客'
            },
            {
              id: 11,
              path: '/comment',
              title: '评论',
              parentTitle: '博客'
            },
            {
              id: 12,
              path: '/column',
              title: '栏目',
              parentTitle: '博客'
            },
            {
                id: 13,
                path: '/music',
                title: '音乐',
                parentTitle: '博客'
            },
            {
              id: 14,
              path: '/tag',
              title: '标签',
              parentTitle: '博客'
            },
          ]
        },
        {
          id: 2,
          title: '小说',
          icon: 'el-icon-menu',
          children: [
            {
              id: 20,
              path: '/category',
              title: '分类',
              parentTitle: '小说'
            },
            {
              id: 21,
              path: '/author',
              title: '作者',
              parentTitle: '小说'
            },
            {
              id: 22,
              path: '/book',
              title: '书籍',
              parentTitle: '小说'
            },
            {
              id: 23,
              path: '/chapter',
              title: '章节',
              parentTitle: '小说'
            },
            {
              id: 24,
              path: '/collect',
              title: '收藏',
              parentTitle: '小说'
            },
            {
              id: 25,
              path: '/keyword',
              title: '关键词',
              parentTitle: '小说'
            },
          ]
        },
        {
          id: 3,
          title: '用户',
          icon: 'el-icon-user',
          children: [
            {
              id: 30,
              path: '/user',
              title: '用户',
              parentTitle: '用户'
            }
          ]
        },
        {
          id: 4,
          title: '应用',
          icon: 'el-icon-menu',
          children: [
            {
                id: 40,
                path: '/advertise',
                title: '广告',
                parentTitle: '应用'
            },
            {
                id: 41,
                path: '/special',
                title: '专题',
                parentTitle: '专题'
            }
          ]
        },
        {
          id: 5,
          title: '系统',
          icon: 'el-icon-s-promotion',
          children: [
            {
              id: 50,
              path: '/admin',
              title: '管理员',
              parentTitle: '系统'
            },
            {
              id: 51,
              path: '/role',
              title: '角色',
              parentTitle: '系统'
            },
            {
              id: 52,
              path: '/department',
              title: '部门',
              parentTitle: '系统'
            },
            {
              id: 53,
              path: '/profile',
              title: '配置',
              parentTitle: '系统'
            },
            {
                id: 54,
                path: '/decision',
                title: '决策',
                parentTitle: '系统'
            },
          ]
        }
      ],
      isCollapse: false, // 是否水平折叠收起菜单
      activePath: '/index', // 选中menu的url
      activeText: '首页', // 选中menu的文本
      currentActive: 0, // 点击Tag标签
      tags: [
        {name: '首页', closable: false, path: '/index', parentTitle: ''},
      ],
      breadcrumbList: [], // 面包屑导航数组
      options: [], // 搜索方法
      value: '', // 搜索value
      selectList: [], // 搜索列表
      loading: false, // 搜索等待
      username: '', // 用户名
    }
  },
  created() {
    const token = this.$store.state.token;
    if(!token) return this.$router.push({name: 'Login'});
    const user_info = this.$store.state.user_info;
    this.username = user_info.name;
    this.enterPage();
  },
  mounted() {
    // 获取搜索列表
    this.menusList.forEach(item => item.children.forEach(tag => {
      const {parentTitle, title} = tag;
      const str = `${parentTitle} > ${title}`;
      this.selectList.push({...tag, value: str, label: str});
    }));
  },
  methods: {
    enterPage() { // 首次进入页面
      const {path} = this.$route;
      let newList = [];
      this.menusList.forEach(item => item.children.forEach(child => newList.push(child)));
      const filterTag = newList.filter(item => item.path === path);
      let regexp = /\/profile\/\d+/;
      if (path === '/index') {
        return;
      } else if (path === '/mine') {
        this.getBreadcrumbList('', '个人中心');
        this.getTagsList('个人中心', '/mine', '');
      } else if (regexp.test(path)) {
        this.getBreadcrumbList('', '配置值');
        this.getTagsList('配置值', path, '');
      } else {
        const {title: name, parentTitle} = filterTag[0];
        this.getBreadcrumbList(parentTitle, name);
        this.getTagsList(name, path, parentTitle);
      }
    },
    toIndex() { // 点击跳转首页
      const {name} = this.$route;
      if(name === 'Index') return;
      this.activePath = '/index';
      this.activeText = '首页';
      this.currentActive = 0;
      this.breadcrumbList = [];
      this.$router.push({name: 'Index'});
    },
    getCollapse() {this.isCollapse = !this.isCollapse}, // 控制左侧菜单栏
    handleSelect(index, indexPath, e) { // 点击menu
      const {title} = this.menusList[indexPath[0]];
      this.getBreadcrumbList(title, e.$el.innerHTML);
      this.getTagsList(e.$el.innerHTML, index, title);
    },
    getTagIndex(tag, index) { // 点击tag标签
      this.currentActive = index;
      const {name, path, parentTitle} = tag;
      this.getBreadcrumbList(parentTitle, name);
      if(this.$route.path === path) return;
      this.$router.push(path);
      this.activePath = path;
      this.activeText = name;
    },
    closeTag(tag, index) { // 关闭tag标签
      const {name} = tag;
      const {activeText} = this;
      this.tags.splice(index, 1);
      if(name === activeText) {
        const {name: tagName, path: tagPath} = this.tags[this.tags.length - 1];
        this.currentActive = this.tags.length - 1;
        this.activeText = tagName;
        this.activePath = tagPath;
        this.$router.push(tagPath);
      }else {
        const tagIndex = this.tags.findIndex(item => item.name === activeText);
        this.currentActive = tagIndex;
      }
      const {parentTitle, name: title} = this.tags[this.currentActive];
      this.getBreadcrumbList(parentTitle, title);
    },
    getTagsList(name, path, parentTitle) { // 新增tags标签
      this.activePath = path;
      this.activeText = name;
      const index = this.tags.findIndex(item => item.name === name);
      if(index !== -1) return this.currentActive = index;
      this.tags.push({name, closable: true, path, parentTitle});
      this.currentActive = this.tags.length - 1;
    },
    getBreadcrumbList(parentTitle, title) { // 获取面包屑数组
      this.breadcrumbList = [];
      if(title === '首页') return this.breadcrumbList = [];
      if(title === '个人中心') return this.breadcrumbList.push(title);
      this.breadcrumbList.push(parentTitle, title);
    },
    remoteMethod(query) { // 搜索的方法
      if (query !== '') {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.options = this.selectList.filter(item => item.label.toLowerCase().indexOf(query.toLowerCase()) > -1)
        }, 200);
      } else {
        this.options = [];
      }
    },
    getSelectValue() { // 获取搜索的值，跳转页面
      const {parentTitle, path, title} = this.options.filter(item => item.label === this.value)[0];
      this.getBreadcrumbList(parentTitle, title);
      this.getTagsList(title, path, parentTitle);
      this.value = '';
      this.options = [];
      if(this.$route.path === path) return;
      this.$router.push(path);
    },
    getFullScreen() { // 全屏
      let fullscreenElement = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement;
      let docElm = document.documentElement;
      if(!fullscreenElement) {
        if(docElm.requestFullscreen) {
          docElm.requestFullscreen();
        }
        //FireFox
        else if(docElm.mozRequestFullScreen) {
          docElm.mozRequestFullScreen();
        }
        //Chrome等
        else if(docElm.webkitRequestFullScreen) {
          docElm.webkitRequestFullScreen();
        }
        //IE11
        else if(elem.msRequestFullscreen) {
          elem.msRequestFullscreen();
        }
      }else {
        //W3C
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
        //FireFox
        else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        }
        //Chrome等
        else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
        //IE11
        else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      }
    },
    getCommand(command) { // 选择用户下拉
      console.log(command);
      if(command === 'logout') {
        this.logout();
      }else if(command === 'mine') {
        this.getBreadcrumbList('', '个人中心');
        this.getTagsList('个人中心', '/mine', '');
        if(this.$route.name === 'Mine') return;
        this.$router.push({name: 'Mine'});
      }
    },
    logout() { // 退出登录
      this.$confirm('是否确定退出登录?', '退出登录', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message.success('退出成功!');
        delCookies('user_info', 'token', 'refresh_token');
        this.$store.commit('setToken', '');
        this.$store.commit('setRefreshToken', '');
        this.$store.commit('setUserInfo', '');
        setTimeout(() => this.$router.replace({name: 'Login'}), 1000);
      }).catch(() => {
        this.$message.info('已取消退出');
      });
    }
  }
}
</script>

<style lang="less" scoped>
.home_container {
  height: 100%;
  .el-header {
    padding: 0;
    height: 80px !important;
    background: #fff;
    .navbar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 50px;
      min-width: 750px;
      box-shadow: 0 2px 4px #eee;
      .navbar_l {
        display: flex;
        align-items: center;
        height: 50px;
        line-height: 50px;
        .header_collapse, .refresh {
          height: 100%;
          line-height: 50px;
          cursor: pointer;
          i {
            padding: 0 15px;
            font-size: 20px;
            line-height: 50px;
          }
        }
        .el-breadcrumb {
          height: 100%;
          padding: 0 15px;
          line-height: 50px;
          .bread_index {
            color: #000;
            font-weight: 600;
            cursor: pointer;
            &:hover {
              color: #409EFF;
            }
          }
        }
      }
      .navbar_r {
        display: flex;
        height: 50px;
        line-height: 50px;
        .header_full {
          padding: 0 15px;
          font-size: 20px;
          cursor: pointer;
          .iconfont {
            font-size: 20px;
          }
        }
        .el-dropdown {
          cursor: pointer;
          .el-dropdown-link {
            padding: 0 15px;
            display: block;
          }
        }
        .el-select {
          padding: 0 15px;
          /deep/ .el-input__inner {
            height: 30px;
            line-height: 30px;
          }
        }
      }
    }
    .tag {
      width: 100%;
      height: 30px;
      min-width: 750px;
      white-space: nowrap;
      /deep/ .el-scrollbar__bar {
        top: 22px;
      }
      .el-scrollbar {
        box-shadow: 0px 1px 2px #999;
        z-index: 9;
        /deep/ .el-scrollbar__wrap {
          margin-bottom: -15px !important;
        }
        /deep/ .el-scrollbar__bar {
          top: 24px;
        }
      }
      .el-tag {
        height: 26px;
        margin: 2px 4px 0 4px;
        padding: 0 8px;
        color: #495060;
        border: 1px solid #d8dce5;
        background: #fff;
        border-radius: 0;
        line-height: 26px;
        cursor: pointer;
        &:first-child {
          margin-left: 15px;
        }
        /deep/ .el-tag__close {
          color: #495060;
          &:hover {
            color: #fff;
            background: #495060;
          }
        }
      }
      .tag_active {
        color: #fff;
        background: #42b983;
        border-color: #42b983;
        /deep/ .el-tag__close {
          color: #fff;
        }
        &::before {
          content: '';
          position: relative;
          display: inline-block;
          width: 8px;
          height: 8px;
          margin-right: 4px;
          background: #fff;
          border-radius: 50%;
          line-height: 26px;
        }
      }
    }
  }

  .el-aside {
    background: #304156;
    .el-menu {
      border: none;
      > div {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #2B2F3A;
        cursor: pointer;
        img {
          width: 32px;
          height:32px;
          // margin: 0 20px;
        }
        span {
          margin-left: 20px;
          color: #fff;
          font-size: 14px;
          font-weight: 600;
        }
      }
      .el-submenu .el-menu .el-menu-item {
        background: #1f2d3d!important;
      }
      .el-submenu .el-menu .el-submenu__title {
        background: pink;
      }
    }
  }

  .el-main {
    min-width: @minWidth;
    background: #F2F4F7;
  }
}

.header_dropdown {
  padding: 5px 0;
  .el-dropdown-menu__item {
    padding: 0 15px;
  }
  .el-dropdown-menu__item--divided:before {
    margin: 0 -15px;
  }
}
</style>
